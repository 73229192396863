export function PurPur(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="47"
      fill="none"
      viewBox="0 0 37 47"
      {...props}
    >
      <path
        fill="#000"
        d="M13.63 46.59.196 35.002l2.771-3.213.893.46c.03-.638.16-1.248.39-1.83.228-.605.602-1.21 1.124-1.814 1.201-1.393 2.476-2.162 3.823-2.307 1.345-.169 2.849.464 4.51 1.897 1.649 1.422 2.556 2.903 2.721 4.444.166 1.54-.358 3.014-1.571 4.42-.805.933-1.66 1.57-2.566 1.912l4.537 3.913-3.196 3.706Zm-2.258-10.886c.465-.539.634-1.115.507-1.729-.127-.614-.587-1.262-1.379-1.945-.766-.66-1.404-.97-1.915-.93-.499.028-.987.318-1.463.87a2.858 2.858 0 0 0-.713 1.929l3.272 2.821c.337-.075.635-.185.894-.328.26-.143.525-.372.797-.688Zm8.38-4.716-9.23-7.96 3.078-3.569.825.54c.108-.571.326-1.231.654-1.98.329-.747.734-1.44 1.216-2.079l2.727 2.351c-.27.365-.547.778-.833 1.242-.275.45-.525.91-.75 1.381a6.718 6.718 0 0 0-.488 1.195l5.998 5.173-3.197 3.706Zm6.874-7.97-9.23-7.96 3.078-3.569.825.54c.108-.571.326-1.231.654-1.98.329-.747.734-1.44 1.216-2.079l2.727 2.352c-.27.364-.547.777-.833 1.24-.275.451-.525.911-.75 1.382a6.722 6.722 0 0 0-.488 1.195l5.998 5.173-3.197 3.706Zm6.874-7.97-9.23-7.96 3.078-3.569.825.54c.108-.571.326-1.231.654-1.98A10.37 10.37 0 0 1 30.043 0l2.727 2.351c-.27.365-.547.778-.833 1.242-.275.45-.525.91-.75 1.381a6.72 6.72 0 0 0-.488 1.195l5.998 5.173-3.197 3.706Z"
      />
    </svg>
  );
}
