export function MagareVinkoSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="133"
      height="134"
      fill="none"
      viewBox="0 0 133 134"
    >
      <rect width="128" height="128" x="5" y="6" fill="#FFB422" rx="64" />
      <g clip-path="url(#a)">
        <path
          fill="#3F3423"
          fill-rule="evenodd"
          d="M35.497 51.505C10.771 57.058-1.674 59.853 1 85.825c1.92 3.66 3.924 4.384 8.36 4.436l.473 2.694 5.678 3.168-1.577 4.278s-.363 2.73.315 4.606c1.156 3.2 8.201 2.852 8.201 2.852l11.829-6.825c.91.332 1.78.655 2.616.965 7.12 2.641 11.83 4.388 18.045 3.472l3.47 2.851 4.258-1.109 12.144 5.387c9.076-1.562 12.574-4.273 17.507-10.615l16.718 6.337 9.571 1.679c7.892-8.5 15.892-22.5 14.003-46.673l-4.648.634-.789-2.377-1.735 1.743-1.262-3.169-1.577 1.901-.63-2.376-1.736 1.585-.788-3.328-1.577 1.743-.474-2.376-2.365.633.789-1.108-1.893.475V55.09h-2.524v-1.584l-2.365.475 2.365-9.823 16.403-24.556c4.559-22.35 2.201-25.155-11.829-10.615l-13.09 24.556-14.037 8.714-16.403-.95c-14.298 5.113-26.682 7.894-36.949 10.2Z"
          clip-rule="evenodd"
        />
        <path
          fill="#584F41"
          d="M103.778 12.63C96.862 1.864 89.741 42.256 89.741 42.256l14.037-8.714s4.408-14.05 0-20.912Z"
        />
        <path
          fill="#AF7F7F"
          d="m10.882 93.112-.946-4.277 11.671-13.308 3.312 3.327 2.681 5.545-.789 7.921-15.929.792Z"
        />
        <path
          fill="#EEE6D0"
          d="M68.607 53.505s-1.83-4.313-1.105-6.97c1.614-5.907 15.457-2.22 15.457-2.22s5.573 2.607 8.832 3.803c.919 5.129 6.196 5.8 9.305 9.03-1.076 3.17-8.325 7.486-14.825 7.605-8.166.15-17.664-11.248-17.664-11.248Zm35.801-5.546-2.996-3.802c4.2-6.541 6.532-10.37 11.04-23.13 3.467-5.356 4.94-7.717 8.516-12.358 3.786-4.911 6.483-5.905 7.571 0l-2.996 14.259-6.783 12.832-4.731 4.12-1.577-1.11 2.996-3.643s3.944-10.456 3.944-12.2c0-1.05-1.491-2.87-1.893-1.901-.789 1.902-6.151 16.635-6.151 16.635v3.17l-4.89 5.702-2.05 1.426Zm-68.291 3.486c1.316 15.115 3.025 23.473 8.043 38.182 1.228 5.867-.394 8.28-8.043 10.774l-7.267 2.973c-.713.894-1.817 1.884-3.458 3.047-2.691.907-4.37 1.214-8.832 0-2.783-3.841-2.048-5.889 0-9.506 0 0 .224 4.436 2.84 4.277 1.74-.105 2.207-1.9 2.207-1.9 1.227-3.585 1.827-5.62 2.523-9.348 2.43-4.202 3.143-6.606-2.523-11.407l-5.993 7.922-2.996 3.485H8.2c-2.295-.088-3.385-.673-5.204-2.06C1.424 86.545.676 85.216 0 79.964l1.104-4.595.686-2.297-.213-.08.443-.692.976-3.268c1.561-5.018 5.904-9.88 12.875-11.977 5.364-2.986 10.406-4.48 20.246-5.608Z"
        />
        <path
          fill="#EEE1BF"
          d="M19.556 89.47c-4.662 2.654-7.029 3.826-8.99 3.009 1.157 1.842.605 3.111 3.313 3.803 3.729-.198 5.647.037 7.255-3.01l-1.578-3.803Z"
        />
        <path
          fill="#000"
          fill-rule="evenodd"
          d="M83.065 50.19a5.195 5.195 0 0 0-2.978 1.211 5.235 5.235 0 0 0-1.704 2.736l.003.002c.699.617 1.214 1.255 1.702 1.86.862 1.067 1.638 2.028 3.187 2.576 1.198.424 2.887.362 4.11.232a5.222 5.222 0 0 0 1.124-2.24c-.283-1.03-.707-2.24-1.291-3.062-1.21-1.703-2.3-2.704-4.153-3.314Z"
          clip-rule="evenodd"
        />
        <path
          fill="#000"
          d="M97.941 59.014C89.912 51.1 85.537 47.68 78.338 46.478c0 0 6.346-.506 9.927 1.04 5.377 2.322 9.676 11.496 9.676 11.496Z"
        />
        <path
          fill="#fff"
          d="M82.328 53.03a.949.949 0 0 0 .947-.95.949.949 0 0 0-.947-.952.949.949 0 0 0-.946.951c0 .525.424.951.946.951Z"
        />
        <path
          fill="#3F3423"
          d="M13.53 76.496c3.337-1.522 5.34-4.31 4.474-6.226-.867-1.916-4.274-2.234-7.611-.712-3.337 1.523-5.34 4.31-4.474 6.226.867 1.916 4.274 2.235 7.611.712Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h133v113H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
